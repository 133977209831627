import Payload from "./Payload"

class AnalyticsHandler {
  constructor() {
    this.payload = new Payload()
  }

  sendBannerView(data) {
    // if (!data) return
    this.payload.loadBanner(data)

    vpanalytics.notifyHandlers("onBannerView", this.payload)
  }

  sendBannerClick(data) {
    // if (!data) return
    this.payload.loadBanner(data)
    vpanalytics.notifyHandlers("onBannerClick", this.payload)
  }

  sendShowCaseView(data) {
    // if (!data) return
    this.payload.loadShowCase(data)

    vpanalytics.notifyHandlers("onShowCaseView", this.payload)
  }

  sendShowCaseClick(data) {
    // if (!data) return
    this.payload.loadShowCase(data)

    vpanalytics.notifyHandlers("onShowCaseClick", this.payload)
  }

  sendPageView() {
    vpanalytics.notifyHandlers("onPageView")
  }
}

export default AnalyticsHandler
