
export default function useIsElementOnView(el_traget, callback, stopObserver = false) {
    // console.log('el_traget IntersectionObserver', el_traget)

    const observer = new window.IntersectionObserver(
        ([entry]) => {
            if (entry.isIntersecting) {

                if (callback) {

                    if (stopObserver) {
                        observer.unobserve(el_traget)
                    }

                    callback()
                }
                return;
            }
        },
        {
            root: null,
            threshold: 0.1, // set offset 0.1 means trigger if atleast 10% of element in viewport
        }
    );

    if (el_traget) {

        observer.observe(el_traget);
    } else {
        throw ('Cannot set scroll event: ', callback)
    }
}